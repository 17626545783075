import { useDispatch, useSelector } from "react-redux";
import {
  connect,
  ConnectionState,
  getCachedCurrent,
  refreshAllContracts,
  refreshSingleContract,
} from "./web3";

// This contains react-hooks for interacting with the EVM.

// The kName should refer to a defined contract within CONTRACTS (e.g. "member")
export function useContract(kName) {
  let dispatch = useDispatch();
  let connectionState = useSelector((state) => state.web3.connectionState);
  let kState = useSelector((state) => state.web3.contracts[kName]);
  let { contracts } = getCachedCurrent() || {};
  return {
    interface: (contracts || {})[kName],
    state: kState,
    connectionState,
    refresh: async () => await dispatch(refreshSingleContract({ kName })),
  };
}

export function useIsConnected() {
  return useSelector(
    (state) => state.web3.connectionState === ConnectionState.connected
  );
}

export function useWeb3() {
  let dispatch = useDispatch();
  let address = useSelector((state) => state.web3.address);
  let addressName = useSelector((state) => state.web3.addressName);
  let balance = useSelector((state) => state.web3.balance);
  let connectionState = useSelector((state) => state.web3.connectionState);
  let { provider, signer, contracts } = getCachedCurrent() || {};
  return {
    // utilities
    provider,
    signer,
    contracts,

    // state
    connectionState,
    address,
    addressName,
    balance,

    // methods
    connect: () => dispatch(connect()),
    refresh: async () => await dispatch(refreshAllContracts()),
  };
}
