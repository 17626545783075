export const ETHERSCAN_BASE_URL =
  {
    rinkeby: "https://rinkeby.etherscan.io",
    mainnet: "https://etherscan.io",
  }[process.env.ETH_NETWORK_NAME] || "http://etherscan.localhost";

export function urlForAddress(address) {
  return `${ETHERSCAN_BASE_URL}/address/${address}`;
}

export function urlForContract(contractAddress) {
  return `${ETHERSCAN_BASE_URL}/address/${contractAddress}`;
}

export function urlForToken(contractAddress, tokenId = null) {
  if (tokenId === null) {
    return `${ETHERSCAN_BASE_URL}/token/${contractAddress}`;
  }
  return `${ETHERSCAN_BASE_URL}/token/${contractAddress}?a=${tokenId}#inventory`;
}

export function urlForTransaction(txId) {
  return `${ETHERSCAN_BASE_URL}/tx/${txId}`;
}
