import React from "react";
import { Helmet } from "react-helmet";
import "@fontsource/lora/400.css";
import "@fontsource/lora/variable.css";
import "@fontsource/lora/400-italic.css";
import "@fontsource/lora/variable-italic.css";
import Header from "../components/Header.js";
import Footer from "../components/Footer.js";
import study_image_1 from "../images/study_image_1.jpg";
import { useSiteMetadata } from "../hooks/useSiteMetadata";

const Layout = ({ children }) => {
  let { title, siteUrl, twitterUsername, description } = useSiteMetadata();
  return (
    <div>
      <Helmet
        defer={false}
        title={title}
        meta={[
          {
            name: "description",
            content: description,
          },
          {
            name: "twitter:card",
            content: "summary",
          },
          {
            name: "twitter:creator",
            content: twitterUsername,
          },
          {
            property: "og:url",
            content: siteUrl,
          },
          {
            property: "og:title",
            content: title,
          },
          {
            property: "og:description",
            content: description,
          },
          {
            property: "og:image",
            // HACK: relative URLs are not permitted in open graph.
            content: `${siteUrl}${study_image_1}`,
          },
        ]}
      />
      <Header />
      <main style={{ minHeight: "100vh" }}>{children}</main>
      <Footer />
    </div>
  );
};

export default Layout;
