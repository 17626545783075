import * as React from "react";
import "normalize.css";
import "../styles/header.scss";
import { AnchorLink } from "gatsby-plugin-anchor-links";
import { useLocation } from "@reach/router";
import { useContract, useWeb3 } from "../eth/hooks";
import SocietyIcon from "../images/ic_soc.svg";
import DiscordIcon from "../images/ic_disc.svg";
import TwitterIcon from "../images/ic_twit.svg";
import { ellipsized } from "../eth/utils";
import ConnectWalletButton from "../components/ConnectWalletButton.js";

const TestNetworkIndicator = () => {
  // TODO: raise hell if they're on the wrong network
  // let chainId = useSelector((state) => state.web3.chainId);
  if (process.env.ETH_NETWORK_NAME === "mainnet") {
    return null;
  }
  return (
    <span className="network-indicator">{process.env.ETH_NETWORK_NAME}</span>
  );
};

const WalletConnection = () => {
  let { address, addressName } = useWeb3();
  return (
    <>
      {address ? (
        <a className="address" href="/account">
          {addressName || ellipsized(address)}
        </a>
      ) : (
        <ConnectWalletButton square />
      )}
      <TestNetworkIndicator />
    </>
  );
};

const Header = () => {
  let [opened, setOpened] = React.useState(false);
  let overlayClazz = "menu-overlay" + (opened ? " opened" : "");
  let memberK = useContract("member");
  let memberBalance = memberK.state.balance;
  let isMember = memberBalance > 0;
  return (
    <div className="header">
      <div className="container">
        <div className="left-lockup">
          <a className="logo-btn" href="/">
            <SocietyIcon className={isMember ? "logo-member" : "logo"} />
          </a>
          {isMember ? (
            <span className={"member-indicator"}>
              Collector •{memberBalance === 2 ? "•" : null}
            </span>
          ) : null}
        </div>
        <div />
        <Links />
        <a className="toggle" onClick={() => setOpened(!opened)}>
          ☰
        </a>
      </div>
      <div className={overlayClazz}>
        <Links onLinkScrolled={() => setOpened(false)} />
      </div>
    </div>
  );
};

const Links = ({ onLinkScrolled }) => {
  const location = useLocation();
  return (
    <div className="links">
      <AnchorLink
        onAnchorLinkClick={onLinkScrolled}
        to="/#art"
        gatsbyLinkProps={{ state: { from: location.pathname, to: "/#art" } }}
        stripHash
      >
        art
      </AnchorLink>
      <AnchorLink
        onAnchorLinkClick={onLinkScrolled}
        to="/#calendar"
        gatsbyLinkProps={{
          state: { from: location.pathname, to: "/#calendar" },
        }}
        stripHash
      >
        calendar
      </AnchorLink>
      <AnchorLink
        onAnchorLinkClick={onLinkScrolled}
        to="/#faq"
        gatsbyLinkProps={{ state: { from: location.pathname, to: "/#faq" } }}
        stripHash
      >
        faq
      </AnchorLink>
      <div className="slinks">
        <a
          target="_blank"
          rel="noreferrer"
          href="https://discord.gg/BySDwczH6v"
        >
          <DiscordIcon className="ic-soc" />
        </a>
        <a
          target="_blank"
          rel="noreferrer"
          href="https://twitter.com/thesocietyart"
        >
          <TwitterIcon className="ic-soc" />
        </a>
      </div>
      <div className="wrapper-connect">
        <WalletConnection />
      </div>
    </div>
  );
};

export default Header;
