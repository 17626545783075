export const OPENSEA_BASE_URL =
  {
    rinkeby: "https://testnets.opensea.io",
    mainnet: "https://opensea.io",
  }[process.env.ETH_NETWORK_NAME] || "http://opensea.localhost";

export const ADDRESS_CHAR_LENGTH = 42;
export const TRANSACTION_CHAR_LENGTH = 66;
export const DEFAULT_LEFT_CHARS = 6;
export const DEFAULT_RIGHT_CHARS = 4;

export const CHARS_TO_SHOW = {
  [ADDRESS_CHAR_LENGTH]: [6, 4],
  [TRANSACTION_CHAR_LENGTH]: [8, 6],
};

export function ellipsized(chars) {
  if (chars.length < 5) {
    return "";
  }
  let leftChars = CHARS_TO_SHOW[chars.length]
    ? CHARS_TO_SHOW[chars.length][0]
    : DEFAULT_LEFT_CHARS;
  let rightChars = CHARS_TO_SHOW[chars.length]
    ? CHARS_TO_SHOW[chars.length][1]
    : DEFAULT_RIGHT_CHARS;
  chars = chars || "";
  return (
    chars.substr(0, leftChars) +
    "…" +
    chars.substr(chars.length - rightChars, chars.length)
  );
}

const ART_URLS = {
  member: (tokenId) =>
    `https://tokens.thesociety.art/member/${tokenId
      .toString()
      .padStart(4, "0")}.jpg`,
  memberV1: (tokenId) =>
    `https://tokens.thesociety.art/memberV1/placeholder.jpg`,
  initialArtSale: (tokenId) =>
    `https://tokens.thesociety.art/initialArtSale/placeholder.jpg`,
};
const DEFAULT_ART_URL = "https://tokens.thesociety.art/placeholder.jpg";

export function imageUrlForArt(kName, tokenId) {
  return (ART_URLS[kName] || (() => DEFAULT_ART_URL))(tokenId);
}

const OPENSEA_COLLECTION_URLS = {
  member: `${OPENSEA_BASE_URL}/collection/the-society-art`,
  memberV1: `${OPENSEA_BASE_URL}/collection/the-society-art-og`,
  initialArtSale: `${OPENSEA_BASE_URL}/collection/a-survey-of-the-gods`,
};

export function openSeaUrlForCollection(kName) {
  return OPENSEA_COLLECTION_URLS[kName] || OPENSEA_COLLECTION_URLS.member;
}

export function openSeaUrlForToken(contractAddress, tokenId) {
  return `${OPENSEA_BASE_URL}/assets/${contractAddress}/${tokenId}`;
}

// From SocietyMember.sol
export const MemberKMode = {
  // Happy path:
  SellingPreThreshold: 0, // before sales deadline < 2,000 sold
  SellingPostThreshold: 1, // > 2,000 sold, < 5,000 sold
  SoldOut: 2, // 5,000 sold
  // Sad path:
  Refunding: 3, // < 2,000 sold, after sales deadline before refund deadline
  ClosingAfterRefundPeriod: 4, // < 2,000 sold, after refund deadline
};
