import * as React from "react";

const NotFoundPage = () => {
  return (
    <div
      style={{
        maxWidth: 640,
        marginLeft: "auto",
        marginRight: "auto",
        textAlign: "center",
        overflow: "hidden",
      }}
    >
      <h3
        style={{
          fontFamily: "Prata",
          fontSize: "2.1em",
          fontWeight: 100,
          textTransform: "uppercase",
          letterSpacing: "0.1em",
          color: "#333",
        }}
      >
        page not found
      </h3>
      <p
        style={{
          paddingLeft: 50,
          paddingRight: 50,
          fontFamily: "Lora",
          lineHeight: "1.5em",
          color: "#444",
        }}
      >
        Sorry, we couldn’t find what you were looking for.
      </p>
    </div>
  );
};

export default NotFoundPage;
