import React from "react";
import { Router } from "@reach/router";
import Loadable from "@loadable/component";
import "normalize.css";
import "../styles/index.scss";
import "@fontsource/roboto";
import Layout from "../components/Layout.js";
import TermsPage from "../components/Terms.js";
import NotFoundPage from "../components/404.js";

const Home = Loadable(() => import("../components/Home.js"));
const TwitterInviteModal = Loadable(() =>
  import("../components/TwitterInviteModal.js")
);
const Account = Loadable(() => import("../components/Account.js"));
const Member = Loadable(() => import("../components/Member.js"));

const TwitterInvite = (props) => (
  <div>
    <Home fallback={<div className="loader" />} />
    <TwitterInviteModal {...props} />
  </div>
);

const IndexPage = () => {
  return (
    <Layout>
      <Router basepath="/">
        <Home fallback={<div className="loader" />} path="/" />
        <Home fallback={<div className="loader" />} path="/meet-alex" />
        <Member
          fallback={<div className="loader" />}
          path="/member/:memberTokenId"
        />
        <TwitterInvite path="/:twUserHandle" />
        <TermsPage path="/terms" />
        <Account path="/account" />
        <NotFoundPage default />
      </Router>
    </Layout>
  );
};

export default IndexPage;
