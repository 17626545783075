import * as React from "react";
import "normalize.css";
import "../styles/footer.scss";
import { Link } from "gatsby";
import ic_soc_white_250 from "../images/ic_soc_white_250px.png";
import DiscordIcon from "../images/ic_disc.svg";
import TwitterIcon from "../images/ic_twit.svg";
import OpenseaIcon from "../images/ic_opensea.svg";
import EtherscanIcon from "../images/ic_etherscan.svg";
import Wordmark from "../images/wordmark.svg";
import { urlForAddress } from "../eth/etherscan";

const MEMBER_K_ETHERSCAN_URL = urlForAddress(
  `${process.env.ETH_SOCIETY_MEMBER_K}`
);

const Footer = () => {
  return (
    <div className="footer">
      <div className="wordmark-container">
        <Wordmark />
      </div>
      <img
        className="logo-desktop"
        src={ic_soc_white_250}
        alt="the society logo"
      />
      <div className="right-container">
        <div className="slinks">
          <a
            target="_blank"
            rel="noreferrer"
            href="https://discord.gg/BySDwczH6v"
          >
            <DiscordIcon />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://twitter.com/thesocietyart"
          >
            <TwitterIcon />
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://opensea.io/collection/the-society-art"
          >
            <OpenseaIcon />
          </a>
          <a target="_blank" rel="noreferrer" href={MEMBER_K_ETHERSCAN_URL}>
            <EtherscanIcon />
          </a>
        </div>
        <div className="tos-link">
          <span>{`© ${new Date().getUTCFullYear()} Society Labs LLC`}</span>
          <br />
          <Link to="/terms">Terms & Conditions</Link>
        </div>
        <img
          className="logo-mobile"
          src={ic_soc_white_250}
          alt="the society logo"
        />
      </div>
    </div>
  );
};

export default Footer;
