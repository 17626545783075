import * as React from "react";

const styles = {
  h3: {
    fontFamily: "Lora",
    fontSize: "2.1em",
    fontWeight: 100,
    textTransform: "uppercase",
    letterSpacing: "0.1em",
    color: "#333",
    textAlign: "center",
  },
  h5: {
    fontFamily: "Lora",
    fontSize: "1.1em",
    fontWeight: 800,
    textAlign: "left",
    margin: 0,
    maxWidth: "680px",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
  p: {
    fontFamily: "Lora",
    lineHeight: "1.5em",
    color: "#444",
    textAlign: "left",
    marginTop: ".5em",
    maxWidth: "680px",
    marginLeft: "auto",
    marginRight: "auto",
    paddingLeft: "20px",
    paddingRight: "20px",
  },
};

const TermsPage = () => {
  return (
    <div style={styles.container} id="terms">
      <h3 style={styles.h3}>Terms & Conditions</h3>
      <p style={styles.p}>
        <em>November 1, 2021</em>
      </p>
      <p style={styles.p}>
        The Society is a collection of digital collectibles (“NFTs”) on the
        Ethereum network created by Society Labs LLC. Throughout this website,
        “The Society”, “we”, “us”, and “our” refer to Society Labs LLC. By using
        our site, you agree to be bound by these Terms and Conditions (“Terms”).
        We reserve the right to modify these Terms without notice at any time.
        Your continued use of this site after the date referenced above
        constitutes your acceptance of the Terms as modified on that date.
      </p>
      <p style={styles.p}>
        You are solely responsible for the security and management of your
        private Ethereum wallets and for validating all transactions and smart
        contract interactions generated by this website prior to approving them.
        You understand that there is no ability to revert or modify any
        transaction on the Ethereum network.
      </p>
      <p style={styles.p}>
        This website and any connected services are provided “as is” and “as
        available” without any warranty of any kind. By using this website you
        are accepting sole responsibility for any and all transactions involving
        The Society digital collectibles.
      </p>
      <h5 style={styles.h5}>Ownership</h5>
      <p style={styles.p}>
        The Society facilitates the minting, sale, and exchange of individual
        ERC-721 tokens that correspond to Memberships or Art. By minting or
        purchasing an NFT using our smart contracts, you gain full and complete
        ownership of that individual NFT. The Society has no ability to seize,
        freeze, or otherwise modify the ownership of your NFT.
      </p>
      <h5 style={styles.h5}>Personal Use</h5>
      <p style={styles.p}>
        Subject to your continued compliance with these Terms, we grant you a
        worldwide, royalty-free license to use, copy, and display the purchased
        Art, along with any or extensions that you choose to create or use,
        solely for your own personal, non-commercial use and in connection with
        third-party websites or applications that have verified your ownership
        of the NFT on the Ethereum blockchain, including marketplaces that
        permit the purchase and sale of NFTs.
      </p>
      <h5 style={styles.h5}>Commercial Use</h5>
      <p style={styles.p}>
        Subject to your continued compliance with these Terms, we grant you an
        unlimited, worldwide license to use, copy, and display the purchased Art
        for the purpose of creating derivative works based upon the Art.
      </p>
      <h5 style={styles.h5}>The Society IP</h5>
      <p style={styles.p}>
        Other than the rights to the Art, nothing gives you any rights to any
        other trademarks or other intellectual property rights belonging to
        Society Labs LLC including, without limitation The Society, THE SOCIETY,
        any associated logos including the hooded figure logo, the website
        design and UX, our smart contract code, and any content or assets that
        we produce for publication on social media and other marketing channels.
        We expressly reserve all of these rights.
      </p>
      <h5 style={styles.h5}>
        Limitations of Liability for Gas, Failed Transactions, Smart Contract
        Bugs
      </h5>
      <p style={styles.p}>
        You agree to hold us harmless for any losses you may incur in
        association with minting or purchasing your NFT using our website or
        smart contracts. These potential losses may include gas fees for failed
        transactions, excessive gas fees, and the loss of your NFT due to
        website or smart contract bugs.
      </p>
      <h5 style={styles.h5}>No Guarantees or Future Promises</h5>
      <p style={styles.p}>
        You agree that your purchase of an NFT is all you are guaranteed to
        receive with your initial purchase. Any future potential NFT sales,
        airdrops, community gatherings, or other benefits are ancillary to this
        purchase and not to be taken into consideration with your initial
        purchase. You agree that you are not relying on any future commitments
        by The Society in using this site and purchasing an NFT.
      </p>
      <h5 style={styles.h5}>No Investment</h5>
      <p style={styles.p}>
        We make absolutely no promise or guarantee that these NFTs will be worth
        anything. You understand that NFTs have no inherent monetary value and
        that you may not be able to sell them.
      </p>
      <h5 style={styles.h5}>Taxes</h5>
      <p style={styles.p}>
        You are entirely responsible for any tax liability which may arise from
        minting or selling NFTs.
      </p>
      <h5 style={styles.h5}>Class Action Waiver</h5>
      <p style={styles.p}>
        You agree to waive any class action status. Any legal dispute around The
        Society which you may choose to bring can only be done on an individual
        basis.
      </p>
      <h5 style={styles.h5}>Age</h5>
      <p style={styles.p}>
        You agree that you are over the age of 18, or above the legal age of
        your jurisdiction, whichever is greater.
      </p>
      <h5 style={styles.h5}>Privacy Policy</h5>
      <p style={{ marginBottom: "80px", ...styles.p }}>
        The Society collects data to enable us to operate the website and
        services effectively, and to provide you with the best experience on the
        website. We get some of your data by recording how you interact with the
        website by, for example, using technologies like cookies. Additionally,
        you understand that the Ethereum blockchain is public and all of your
        transaction history initiated through the website will be made public.
        The Society may use third-party apps that collect data for the purpose
        of verifying NFT ownership.
      </p>
    </div>
  );
};

export default TermsPage;
