import * as React from "react";
import "normalize.css";
import "../styles/connectwalletbutton.scss";
import { ConnectionState } from "../eth/web3";
import { useWeb3 } from "../eth/hooks";

const ConnectWalletButton = ({ style, text }) => {
  let { connect, connectionState } = useWeb3();

  if (connectionState === ConnectionState.connected) {
    return (
      <button className="connect" style={style}>
        Connected
      </button>
    );
  }
  if (connectionState === ConnectionState.pending) {
    return (
      <button className="connect" style={style} disabled>
        Connect<span className="btn-full"> Wallet →</span>
      </button>
    );
  }
  return (
    <button className="connect" style={style} onClick={connect}>
      Connect<span className="btn-full"> Wallet →</span>
    </button>
  );
};

export default ConnectWalletButton;
